@import '@horizon/tokens/css/horizon-tokens.css';

.basket-search {
    .summary-header {
        .summary-header-cell {
            background-color: var(--hzn-color-surface);
            padding: 1rem;
        }
        .sortable {
            cursor: pointer;
        }
    }
    .summary-row {
        :hover {
            cursor: pointer;
            .external-link {
                display: block;
            }
        }
        .summary-row-cell {
            background-color: var(--hzn-color-surface);
            border-color: var(--hzn-color-border-neutral-weak);
            padding: 1rem;
            .payment-state {
                margin-top: -6px;
            }
        }
        .external-link-container {
            width: 16px;
            height: 16px;
        }
        .external-link {
            display: none;
        }
    }
}

.monospace {
    color: var(--hzn-color-text-neutral);
    line-height: 1rem;
    font-family: var(--hzn-fontfamily-mono);
    .small {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

.highlight {
    color: var(--hzn-color-tone-interactive);
}
