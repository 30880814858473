@import 'bootstrap/dist/css/bootstrap.css';
@import '@horizon/tokens/css/horizon-tokens.css';

body {
    background-color: var(--hzn-color-background-subdued);
    font-family: var(--hzn-fontfamily-body);
    font-size: var(--hzn-fontsize-body-base);
}

// Typography
.stress {
    color: var(--hzn-color-text-neutral);
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: var(--hzn-fontfamily-display);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.25px;
    margin-top: -0.5rem;
}

// Layout
#main-content {
    height: 100%;
    min-height: 100vh;
}

.w-33 {
    width: 33%;
}

.w-66 {
    width: 66%;
}

.w-90 {
    width: 90%;
}

// style bootstrap tabs as CarMax tabs
.nav-tabs {
    border: none;
    --bs-nav-tabs-border-width: none;
    .nav-link {
        color: inherit;
        background-color: transparent;
        --bs-nav-link-padding-x: 1.25rem;
        --bs-nav-link-padding-y: 0;
        padding-bottom: 1.25rem;
        &.active {
            border-bottom: 5px solid #005ab8;
            background-color: transparent;
        }
        &:not(.active) {
            border-bottom: 5px solid transparent;
            &:hover {
                border-bottom: 5px solid #005ab850;
            }
        }
    }
}

.gateway {
    background-color: var(--hzn-color-background-subdued);
    .card {
        background-color: var(--hzn-color-background);
    }
}

.pointer {
    cursor: pointer;
}

// Bootstrap overrides
.modal,
.modal-backdrop {
    top: 50px;
}

.modal-header {
    padding: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: none;
}

.modal-body {
    padding: 1.5rem;
    padding-top: 1rem;
}

.modal-footer {
    border-top: none;
}

@media (min-width: 576px) {
    .modal-sm {
        --bs-modal-width: 370px;
    }
}

.validated-input {
    min-height: 6rem;
}

.offcanvas {
    z-index: 100000;
}

.offcanvas.offcanvas-start,
.offcanvas.offcanvas-end {
    width: 250px;
}

// .offcanvas-backdrop {
//     display: none;
// }

.fab {
    // floating action button
    position: fixed;
    bottom: 20px;
    right: 20px;
    float: right;
    z-index: 9999;
    cursor: pointer;
    display: block;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    background-color: var(--hzn-color-tone-neutral-faint);
    border: 1px solid var(--hzn-color-tone-neutral);
    filter: drop-shadow(0 0 0.1rem var(--hzn-color-tone-neutral));

    .fab-content {
        margin-top: 7px;
        margin-left: 7px;
    }
}

modal-open body {
    overflow: visible;
}
