@import '@horizon/tokens/css/horizon-tokens.css';

.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-size: 1rem;
}

@media (min-width: 768px) {
    .navbar-brand {
        font-size: 1.25rem;
    }
}

.sidebar {
    background-color: var(--hzn-color-surface);
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--hzn-color-surface);
    border-bottom: 1px solid var(--hzn-color-border-neutral-weak);
}

.navbar-brand {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.navbar-toggler {
    border: none;
}

.Nav-menu-block {
    border-left: 2px solid var(--hzn-color-border-neutral-weak);
    padding: 0.5rem 1rem;
}

.main-content {
    background-color: var(--hzn-color-background-subdued);
    border-left: 1px solid var(--hzn-color-border-neutral-weak);
}

.header_logo {
    fill: var(--hzn-color-text-brand);
}
