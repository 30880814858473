.data-table {
    margin-bottom: 0;
    --bs-table-bg: transparent;
    .summary-header {
        .summary-header-cell {
            padding: 1rem;
        }
        .sortable {
            cursor: pointer;
        }
    }
    .summary-row {
        &.has-click-action:hover {
            cursor: pointer;
        }
        &:last-child {
            border-bottom-color: transparent;
        }
        .summary-row-cell {
            padding: 1rem;
            .pill {
                margin-top: -5px;
            }
        }
    }
}
