@import '@horizon/tokens/css/horizon-tokens.css';

.stepTitleHeader {
    .status {
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: right;
        // TODO: determine if I need to add dark color scheme for partially complete like for Complete
        &.InProgress {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.3135 0.333252 0.333496 3.31325 0.333496 6.99992C0.333496 10.6866 3.3135 13.6666 7.00016 13.6666C10.6868 13.6666 13.6668 10.6866 13.6668 6.99992C13.6668 3.31325 10.6868 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.0535 12.3333 1.66683 9.94658 1.66683 6.99992C1.66683 4.05325 4.0535 1.66659 7.00016 1.66659C9.94683 1.66659 12.3335 4.05325 12.3335 6.99992C12.3335 9.94658 9.94683 12.3333 7.00016 12.3333Z' fill='%23005AB8'/%3E%3C/svg%3E");
        }
        &.PartiallyComplete {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 13.6667C10.6802 13.6667 13.6668 10.68 13.6668 7.00004C13.6668 3.32004 10.6802 0.333374 7.00016 0.333374C3.32016 0.333374 0.333496 3.32004 0.333496 7.00004C0.333496 10.68 3.32016 13.6667 7.00016 13.6667ZM7.66683 1.71337C10.2935 2.04004 12.3335 4.28004 12.3335 7.00004C12.3335 9.72004 10.3002 11.96 7.66683 12.2867V1.71337Z' fill='%23005AB8'/%3E%3C/svg%3E%0A");
        }
        &.Completed {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.06016 12.3333 1.66683 9.93992 1.66683 6.99992C1.66683 4.05992 4.06016 1.66659 7.00016 1.66659C9.94016 1.66659 12.3335 4.05992 12.3335 6.99992C12.3335 9.93992 9.94016 12.3333 7.00016 12.3333ZM10.0602 4.05325L5.66683 8.44658L3.94016 6.72658L3.00016 7.66658L5.66683 10.3333L11.0002 4.99992L10.0602 4.05325Z' fill='%231B6B00'/%3E%3C/svg%3E");
        }
        @media (prefers-color-scheme: dark) {
            &.Complete {
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.06016 12.3333 1.66683 9.93992 1.66683 6.99992C1.66683 4.05992 4.06016 1.66659 7.00016 1.66659C9.94016 1.66659 12.3335 4.05992 12.3335 6.99992C12.3335 9.93992 9.94016 12.3333 7.00016 12.3333ZM10.0602 4.05325L5.66683 8.44658L3.94016 6.72658L3.00016 7.66658L5.66683 10.3333L11.0002 4.99992L10.0602 4.05325Z' fill='%232eb700'/%3E%3C/svg%3E");
            }
        }
    }
}
