.stepper {
    .inactive {
        margin-left: 5px;
    }
    .active {
        border-left: 5px solid #005ab8;
    }
    .status {
        padding-right: 1rem;
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: right;
        &.NotStarted {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.3135 0.333252 0.333496 3.31325 0.333496 6.99992C0.333496 10.6866 3.3135 13.6666 7.00016 13.6666C10.6868 13.6666 13.6668 10.6866 13.6668 6.99992C13.6668 3.31325 10.6868 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.0535 12.3333 1.66683 9.94658 1.66683 6.99992C1.66683 4.05325 4.0535 1.66659 7.00016 1.66659C9.94683 1.66659 12.3335 4.05325 12.3335 6.99992C12.3335 9.94658 9.94683 12.3333 7.00016 12.3333Z' fill='%232A343D'/%3E%3C/svg%3E%0A");
        }
        &.InProgress {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.3135 0.333252 0.333496 3.31325 0.333496 6.99992C0.333496 10.6866 3.3135 13.6666 7.00016 13.6666C10.6868 13.6666 13.6668 10.6866 13.6668 6.99992C13.6668 3.31325 10.6868 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.0535 12.3333 1.66683 9.94658 1.66683 6.99992C1.66683 4.05325 4.0535 1.66659 7.00016 1.66659C9.94683 1.66659 12.3335 4.05325 12.3335 6.99992C12.3335 9.94658 9.94683 12.3333 7.00016 12.3333Z' fill='%23005AB8'/%3E%3C/svg%3E");
        }
        &.PartiallyComplete {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 13.6667C10.6802 13.6667 13.6668 10.68 13.6668 7.00004C13.6668 3.32004 10.6802 0.333374 7.00016 0.333374C3.32016 0.333374 0.333496 3.32004 0.333496 7.00004C0.333496 10.68 3.32016 13.6667 7.00016 13.6667ZM7.66683 1.71337C10.2935 2.04004 12.3335 4.28004 12.3335 7.00004C12.3335 9.72004 10.3002 11.96 7.66683 12.2867V1.71337Z' fill='%23005AB8'/%3E%3C/svg%3E%0A");
        }
        &.Complete {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.06016 12.3333 1.66683 9.93992 1.66683 6.99992C1.66683 4.05992 4.06016 1.66659 7.00016 1.66659C9.94016 1.66659 12.3335 4.05992 12.3335 6.99992C12.3335 9.93992 9.94016 12.3333 7.00016 12.3333ZM10.0602 4.05325L5.66683 8.44658L3.94016 6.72658L3.00016 7.66658L5.66683 10.3333L11.0002 4.99992L10.0602 4.05325Z' fill='%231B6B00'/%3E%3C/svg%3E");
        }
        &.Disabled {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0003 4.66667H9.33366V3.33333C9.33366 1.49333 7.84032 0 6.00033 0C4.16033 0 2.66699 1.49333 2.66699 3.33333V4.66667H2.00033C1.26699 4.66667 0.666992 5.26667 0.666992 6V12.6667C0.666992 13.4 1.26699 14 2.00033 14H10.0003C10.7337 14 11.3337 13.4 11.3337 12.6667V6C11.3337 5.26667 10.7337 4.66667 10.0003 4.66667ZM4.00033 3.33333C4.00033 2.22667 4.89366 1.33333 6.00033 1.33333C7.10699 1.33333 8.00033 2.22667 8.00033 3.33333V4.66667H4.00033V3.33333ZM10.0003 12.6667H2.00033V6H10.0003V12.6667ZM6.00033 10.6667C6.73366 10.6667 7.33366 10.0667 7.33366 9.33333C7.33366 8.6 6.73366 8 6.00033 8C5.26699 8 4.66699 8.6 4.66699 9.33333C4.66699 10.0667 5.26699 10.6667 6.00033 10.6667Z' fill='%236D747A'/%3E%3C/svg%3E");
        }
        @media (prefers-color-scheme: dark) {
            &.NotStarted {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.3135 0.333252 0.333496 3.31325 0.333496 6.99992C0.333496 10.6866 3.3135 13.6666 7.00016 13.6666C10.6868 13.6666 13.6668 10.6866 13.6668 6.99992C13.6668 3.31325 10.6868 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.0535 12.3333 1.66683 9.94658 1.66683 6.99992C1.66683 4.05325 4.0535 1.66659 7.00016 1.66659C9.94683 1.66659 12.3335 4.05325 12.3335 6.99992C12.3335 9.94658 9.94683 12.3333 7.00016 12.3333Z' fill='%23fff'/%3E%3C/svg%3E%0A");
            }
            &.Complete {
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 0.333252C3.32016 0.333252 0.333496 3.31992 0.333496 6.99992C0.333496 10.6799 3.32016 13.6666 7.00016 13.6666C10.6802 13.6666 13.6668 10.6799 13.6668 6.99992C13.6668 3.31992 10.6802 0.333252 7.00016 0.333252ZM7.00016 12.3333C4.06016 12.3333 1.66683 9.93992 1.66683 6.99992C1.66683 4.05992 4.06016 1.66659 7.00016 1.66659C9.94016 1.66659 12.3335 4.05992 12.3335 6.99992C12.3335 9.93992 9.94016 12.3333 7.00016 12.3333ZM10.0602 4.05325L5.66683 8.44658L3.94016 6.72658L3.00016 7.66658L5.66683 10.3333L11.0002 4.99992L10.0602 4.05325Z' fill='%232eb700'/%3E%3C/svg%3E");
            }
        }
    }
    .single-step {
        padding: 1.25rem 0 1.25rem 1.25rem;
    }
    .multi-step {
        .accordion {
            --bs-accordion-bg: transparent;
            --bs-accordion-active-bg: transparent;
            --bs-accordion-border-width: 0;
            --bs-accordion-btn-focus-border-color: transparent;
            --bs-accordion-btn-focus-box-shadow: none;
            .accordion-body > .flex-column {
                gap: 2rem !important;
            }
        }
    }
}
